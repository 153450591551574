import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Upload, Image, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import './style.scss';

export default ({
  FetchApi,
  getImg,
  imageInfo,
  productId,
  onImgChange
}) => {

  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  // 图片上传
  const beforeUpload = async (file, fileList, type) => {
    const uniqueId = uuidv4();
    const fileType = String(file.type).toLocaleLowerCase();

    if (fileType.includes('heic') || fileType.includes('heif')) {
      message.error('暂不支持HEIC/HEIF格式，请上传jpg或png格式图片');
      return;
    }

    if (file.size > 3 * 1024 * 1024) {
      message.error('文件过大，请上传3M以下的图片');
      return;
    }

    const params = {
      file: file,
      fileName: `${productId}_${uniqueId}_detail.png`,
      fileSize: file.size,
      fileType: 'Static',
    }
    await getSignature(params, type);
  }

  const getSignature = async (payload, type) => {
    const result = await FetchApi('POST:oss/createSignature', payload);
    const formData = new FormData();
    formData.append('key', result.ossSignatureDTO.path);
    formData.append('OSSAccessKeyId', result.ossSignatureDTO.keyId);
    formData.append('policy', result.ossSignatureDTO.policy);
    formData.append('signature', result.ossSignatureDTO.signature);
    formData.append('success_action_status', 200);
    formData.append('file', payload.file); // fileInput是一个文件输入元素

    let imageUrl = `${result.ossSignatureDTO.host}/${result.ossSignatureDTO.path}`;

    await fetch(result.ossSignatureDTO.host, {
      method: 'POST', // 指定请求方法
      body: formData
    })

    getImg(imageUrl);

  }

  // 图片转base64
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // 图片预览
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  // 页面删除图片
  const handleChange = ({ fileList: newFileList }) => {
    onImgChange(newFileList);
    setFileList(newFileList);
  };

  useEffect(() => {
    let imgs = [];
    imageInfo?.map((item, index) => {
      let obj = {};
      obj.id = index;
      obj.url = item;
      imgs.push(obj);
    })
    setFileList(imgs);
  }, [imageInfo]);

  return <div>
    <Upload
      action=""
      listType="picture-card"
      fileList={fileList}
      onPreview={handlePreview}
      beforeUpload={(file, fileList) => beforeUpload(file, fileList, 'images')}
      onChange={(info) => handleChange(info, 'images')}
    >
      {
        imageInfo?.length === 12 ? null :
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
      }
    </Upload>
    {previewImage && (
      <Image
        wrapperStyle={{
          display: 'none',
        }}
        preview={{
          visible: previewOpen,
          onVisibleChange: (visible) => setPreviewOpen(visible),
          afterOpenChange: (visible) => !visible && setPreviewImage(''),
        }}
        src={previewImage}
      />
    )}
  </div>
}