import React, { useState, useEffect } from 'react';
import { Form, Upload, message, Button, Input } from 'antd';
import './style.scss';
import UploadImage from '../UploadImage';

export default ({
    FetchApi,
    onConfirm,
    detailInfo,
    form
}) => {

    // 初始化 属性 数据
    const [featuresData, setFeaturesData] = useState([{
        text: '',
        values: ['']
    }]);

    // 更新 属性 数据并重新生成 列表
    const handleSkuUpdate = (updatedSkuData) => {
        // 更新状态
        setFeaturesData(updatedSkuData);
    };

    // 更新 属性项
    const updateSkuItem = (text, updatedItem) => {
        const updatedSkuData = featuresData.map(item => item.text === text ? updatedItem : item);
        handleSkuUpdate(updatedSkuData);
    };

    // 更新 名称或值
    const onInputChange = (record, text, newValue) => {
        const updatedItem = { ...record, [text]: newValue };
        updateSkuItem(record.text, updatedItem);
    };

    const onValueChange = (child, newValue, record) => {
        // const updatedValues = record.values.map(value => value.valueId === child.valueId ? { ...value, value: newValue } : value);
        const updatedValues = record.values.map(value => value === child ? value = newValue : value);
        updateSkuItem(record.text, { ...record, values: updatedValues });
    };

    // 为某个 属性新增值
    const onAddValues = (record) => {
        const values = '';
        const updatedValues = [...record.values, values];
        console.log('updatedValues', updatedValues)
        updateSkuItem(record.text, { ...record, values: updatedValues });
    };

    // 删除某个 属性 值
    const onDelValue = (record, valueToDelete) => {

        if (featuresData?.length === 1 && featuresData?.[0]?.values?.length === 1) {
            message.warning('最少保留1个属性值');
            return;
        }

        const updatedValues = record.values.filter(value => value !== valueToDelete);

        if (updatedValues.length === 0) {
            // 如果删除后没有值了，直接删除该 SKU 属性
            const updatedSkuData = featuresData.filter(item => item.text !== record.text);
            handleSkuUpdate(updatedSkuData);
        } else {
            // 否则更新该 SKU 属性的值
            updateSkuItem(record.text, { ...record, values: updatedValues });
        }
    };

    // 新增 SKU 属性配置项
    const onCreateSku = () => {
        const newSku = {
            text: '',
            values: ['']
        };

        // 更新 SKU 数据
        handleSkuUpdate([...featuresData, newSku]);
    };

    const getImg = (value) => {
        form.setFieldValue('descPath', [...form.getFieldValue('descPath') || [], value]);

        console.log('descPath----0000', form.getFieldValue('descPath'))
    }

    form.setFieldValue('features', featuresData);

    const transferObj = (data) => {
        let arr = [];
        for (let key in data) {
            let obj = {};
            obj.text = key;
            obj.values = data[key];
            arr.push(obj);
        }
        return arr;
    }

    useEffect(() => {
        if (detailInfo && detailInfo.features) {
            const features = transferObj(detailInfo.features);
            setFeaturesData(features);
        }
    }, [detailInfo])

    return <div>
        <Form
            form={form}
            labelAlign="right"
            labelCol={{ flex: '100px' }}
        >

            <Form.Item label="商品属性" name="features">
                <div className='sku-info-block'>
                    {
                        featuresData.map((item, idx) => (
                            <div key={idx} className='sku-tag-list'>
                                <div className='sku-key'>
                                    <Input
                                        className='inp'
                                        value={item.text}
                                        placeholder='属性名称'
                                        onChange={(e) => onInputChange(item, 'text', e.target.value)}
                                    />
                                </div>
                                <div className='sku-val'>
                                    {
                                        item.values.map((child, index) => (
                                            <Input
                                                key={index}
                                                className='inp'
                                                value={child}
                                                placeholder='属性值'
                                                onChange={(e) => onValueChange(child, e.target.value, item)}
                                                suffix={<span className='iconfont icon-jianhao' onClick={() => onDelValue(item, child)} />}
                                            />

                                        ))
                                    }
                                    {
                                        item.values.length < 10 && <span
                                            className='iconfont icon-jiahao'
                                            onClick={() => onAddValues(item)}
                                        />
                                    }
                                </div>
                            </div>
                        ))
                    }
                    {<Button type='dashed' style={{ width: '100%' }} onClick={onCreateSku}>新增属性</Button>}
                </div>
            </Form.Item>
            <Form.Item
                label="图文详情"
                name="extInfo"
                className='pro-desc'
                rules={[
                    {
                        required: true,
                        message: '请上传商品描述图片!',
                    },
                ]}
            >
                <UploadImage
                    FetchApi={FetchApi}
                    getImg={getImg}
                    onImgChange={(vals) => {
                        if (vals.length < form.getFieldValue('descPath').length) {
                            form.setFieldValue('descPath', vals.map(i => i?.url));
                        }
                    }}
                    imageInfo={detailInfo.descPath}
                    productId={detailInfo.productId}
                />
            </Form.Item>
        </Form>
    </div>
}

// 常量：用于生成唯一的 keyId 和 valueId
const WORDS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const NUMBERS = '0123456789'.split('');

// 获取下一个可用的 key
const getNextAvailableKey = (availableKeys, usedKeys) => {
    return availableKeys.find(key => !usedKeys.includes(key)) || null;
};