import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Input, Form, Row, Col, Select, Space, Button, message, Upload, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './style.scss';

const { Option } = Select;

export default ({
  FetchApi,
  onConfirm,
  detailInfo,
  form
}) => {

  const [picImage, setPicImage] = useState();
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleRemove = () => {
  }

  const beforeUpload = async (file, fileList, type) => {

    const fileType = String(file?.type).toLocaleLowerCase();

    if (fileType.includes('heic') || fileType.includes('heif')) {
      message.error('暂不支持HEIC/HEIF格式，请上传jpg或png格式图片');
      return;
    }

    if (file.size > 3 * 1024 * 1024) {
      message.error('文件过大，请上传3M以下的图片');
      return;
    }

    const uniqueId = uuidv4();
    const params = {
      file: file,
      fileName: type === 'images' ? `${detailInfo.productId}_${uniqueId}.png` : `${detailInfo.productId}_main.png`,
      fileSize: file.size,
      fileType: 'Static',
    }

    let imgUrl = await getSignature(params, type);
    form.setFieldValue(type && type === 'images' ? 'images' : 'picUrl', imgUrl);

    if (!type) {
      setPicImage(imgUrl)
    }
  }

  const getSignature = async (payload, type) => {
    const result = await FetchApi('POST:oss/createSignature', payload);

    const formData = new FormData();

    formData.append('key', result.ossSignatureDTO.path);
    formData.append('OSSAccessKeyId', result.ossSignatureDTO.keyId);
    formData.append('policy', result.ossSignatureDTO.policy);
    formData.append('signature', result.ossSignatureDTO.signature);
    formData.append('success_action_status', 200);
    formData.append('file', payload.file); // fileInput是一个文件输入元素

    await fetch(result.ossSignatureDTO.host, {
      method: 'POST', // 指定请求方法
      body: formData
    })
    let imageUrl = `${result.ossSignatureDTO.host}/${result.ossSignatureDTO.path}`;
    if (type && type === 'images') {
      imageUrl = [...form.getFieldValue('images') || [], imageUrl];
    }
    return imageUrl
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    if (newFileList.length < form.getFieldValue('images').length) {
      form.setFieldValue('images', newFileList.map(i => i?.url));
    }
    setFileList(newFileList);
  };

  const handlePicChange = ({ fileList: newFileList }) => setPicImage(newFileList);

  useEffect(() => {
    console.log('detailInfo', detailInfo)
    form.setFieldsValue(detailInfo);
    let imgs = [];
    detailInfo?.images?.map((item, index) => {
      let obj = {};
      obj.id = index;
      obj.url = item;
      imgs.push(obj);
    })
    // console.log(imgs)
    setFileList(imgs);

    let mainImg = detailInfo?.picUrl ? [
      {
        id: 1,
        url: detailInfo && detailInfo.picUrl
      }
    ] : [];
    setPicImage(mainImg);
  }, [detailInfo]);

  // form.setFieldValue('picUrl', picImage);
  // form.setFieldValue('images', fileList);

  return <div>
    <Form
      form={form}
      labelAlign="right"
      labelCol={{ flex: '100px' }}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label="商品名称"
            name="title"
            disabled
            rules={[
              {
                required: true,
                message: '请输入商品名称!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="原价"
            name="marketPrice"
            disabled
            rules={[
              {
                required: true,
                message: '请输入原价!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="现价"
            name="salePrice"
            disabled
            rules={[
              {
                required: true,
                message: '请输入现价!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="商品主图"
        name="picUrl"
        rules={[
          {
            required: true,
            message: '上传商品图片!',
          },
        ]}
      >
        {
          <div className='flex'>
            <div>
              <Upload
                beforeUpload={beforeUpload}
                onChange={handlePicChange}
                fileList={picImage}
                onRemove={handleRemove}
                onPreview={handlePreview}
                listType="picture-card"
                maxCount='1'>
                {
                  picImage && picImage.length ? null :
                    <div>

                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                }
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: 'none',
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
              {/* <div className='text-center'>最多上传1张</div> */}
            </div>

          </div>
        }

      </Form.Item>
      <Form.Item
        label="商品轮播图"
        name="images"
        disabled
        rules={[
          {
            required: true,
            message: '上传商品图片!',
          },
        ]}
      >

        <Upload
          action=""
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          beforeUpload={(file, fileList) => beforeUpload(file, fileList, 'images')}
          onChange={(info) => handleChange(info, 'images')}
        >
          {
            form.getFieldValue('images') === 8 ? null :
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
          }
        </Upload>
        {previewImage && (
          <Image
            wrapperStyle={{
              display: 'none',
            }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(''),
            }}
            src={previewImage}
          />
        )}
      </Form.Item>
    </Form>
  </div>
}